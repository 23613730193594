<template>
  <div>
    <el-card v-if="digital == 1">
      <div class="top">
        <ol>
          <li>
            | 重大隐患统计<br /><br /><span>{{ top.zd_hidd }}</span>
          </li>
          <li>
            | 待整改<br /><br /><span>{{ top.num_pass }}</span>
          </li>
          <li>
            | 已完成<br /><br /><span>{{ top.num_data }}</span>
          </li>
          <li>
            | 逾期未整改<br /><br /><span>{{ top.num_data_not }}</span>
          </li>
        </ol>
      </div>
      <el-card>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="项目名称:">
            <el-input placeholder="请输入项目名称" v-model="pro_name" size="small"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" style="margin-left: 15px" @click="query">查询</el-button>
            <el-button type="warning" size="small" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <!-- //表单 -->
      <el-table :data="tableData" stripe style="width: 100%"
        :header-cell-style="{ background: '#0000CD', color: '#ffffff' }">
        <el-table-column prop="name" label="项目名称"> </el-table-column>
        <el-table-column prop="corporations" label="所属公司">
        </el-table-column>
        <el-table-column prop="site" label="所属区域"> </el-table-column>
        <el-table-column prop="zd_hidd" label="重大隐患统计"> </el-table-column>
        <el-table-column prop="pc" label="待评审"> </el-table-column>
        <el-table-column prop="abarbeitung" label="待整改"> </el-table-column>
        <el-table-column prop="complete" label="已完成"> </el-table-column>
        <el-table-column prop="not_pass" label="逾期未整改"> </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="chakan(scope.row)">查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- //第二个卡片 -->
    <el-card v-else-if="digital == 2">
      <el-button type="primary" round @click="superior" class="butt" size="mini">返回上一级</el-button>
      <div class="con-size">
        <p>
          项目名称:<span style="font-size: 14px">{{ aaa1.name }}</span>
        </p>
        <p>
          所属公司:<span style="font-size: 14px">{{ aaa1.corporations }}</span>
        </p>
        <p>
          所在位置:<span style="font-size: 14px">{{ aaa1.site }}</span>
        </p>
      </div>

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="检查类型:">
          <el-select placeholder="全部" v-model="riskgrade" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="治理状态:">
          <el-select placeholder="全部" v-model="audit_type" size="small">
            <el-option v-for="item in options1" :key="item.value1" :label="item.label1" :value="item.value1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" style="margin-left: 15px" @click="queryday2">查询</el-button>
          <el-button type="warning" size="small" @click="resetday2">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- //表单 -->
      <el-table :data="tableData2" stripe style="width: 100%"
        :header-cell-style="{ background: '#0000CD', color: '#ffffff' }">
        <el-table-column prop="id" label="隐患编号"> </el-table-column>
        <el-table-column prop="riskgrade" label="隐患级别"> </el-table-column>
        <el-table-column prop="tasktype" label="检查类型"> </el-table-column>
        <el-table-column prop="dutyname" label="检查负责人"> </el-table-column>
        <el-table-column prop="refortime" label="检查时间"> </el-table-column>
        <el-table-column prop="reformname" label="受检单位"></el-table-column>
        <el-table-column prop="reformname" label="整改负责人"></el-table-column>
        <el-table-column prop="audit_type" label="治理状态"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="ck(scope.row)">查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- //分页 -->
      <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="page2"
        :page-sizes="[5, 10, 20,]" :page-size="pagesize2" layout="total, sizes, prev, pager, next, jumper"
        :total="total2">
      </el-pagination>
    </el-card>

    <!-- 第三个卡片 -->
    <el-card v-else-if="digital == 3">
      <el-button type="primary" round class="butt" size="mini" @click="ReturnThree">返回上一级</el-button>
      <el-card>
        <div class="day3">
          <el-form ref="form" label-width="80px">
            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="项目名称:">
                <el-input style="margin-left: 30px" v-model="aaa1.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="所属公司:">
                <el-input style="margin-left: 30px" v-model="aaa1.corporations"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="所属区域:">
                <el-input style="margin-left: 30px" v-model="aaa1.site"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="隐患编号:">
                <el-input style="margin-left: 30px" v-model="aaa2.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="隐患级别:">
                <el-input style="margin-left: 30px" v-model="aaa2.riskgrade"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="检查类型:">
                <el-input style="margin-left: 30px" v-model="aaa2.tasktype"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="检查时间:">
                <el-input style="margin-left: 30px" v-model="aaa2.refortime"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="受检单位:">
                <el-input style="margin-left: 30px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="检查人员:">
                <el-input style="margin-left: 30px"></el-input>
              </el-form-item>
            </el-col>


            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="当前状态:">
                <el-input style="margin-left: 30px" v-model="aaa2.audit_type"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="整改时间:">
                <el-input style="margin-left: 30px" v-model="aaa2.refortime"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="负责人:">
                <el-input style="margin-left: 30px" v-model="aaa2.reformname"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6" style="margin-left: 20px;margin-top:13px;">
              <el-form-item label="隐患内容:">
                <el-input type="textarea" style="width: 1350px; height: 109px; margin-left: 30px"
                  v-model="aaa2.details"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </el-card>
    </el-card>
  </div>
</template>
<script>
import {
  addMajorHiddenGovernance,
  addMajorHiddenday2,
} from "../../../request/hidden";
export default {
  name: "",
  props: [],
  data() {
    return {
      tableData: [],
      tableData2: [],
      aaa1: {},
      aaa2: {},
      top: [],
      page: 1,
      pagesize: 10,
      page2: 1,
      pagesize2: 10,
      total: 0, //分页总条数
      total2: 0, //分页总条数
      name: "", //查询
      pro_name: "",
      digital: 1, //用户卡片切换
      id: "", //重大隐患治理台账项目id
      options: [
        {
          label: "低隐患",
          value: "低隐患",
        },
        {
          label: "一般隐患",
          value: "一般隐患",
        },
        {
          label: "较大隐患",
          value: "较大隐患",
        },
        {
          label: "重大隐患",
          value: "重大隐患",
        },
      ],
      options1: [
        {
          label1: "已提交",
          value1: "已提交",
        },
        {
          label1: "审核通过",
          value1: "审核通过",
        },
        {
          label1: "已通过",
          value1: "已通过",
        },
        {
          label1: "未通过",
          value1: "未通过",
        },
      ],
      riskgrade: '',
      audit_type: '',
      pjtid: ''
    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.digital = 1;
      this.shuju();
    },
  },

  methods: {
    async shuju() {
      let msg = {
        page: this.page,
        size: this.pagesize,
        name: this.pro_name,
        unique_identification: localStorage.getItem("uid"),

      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }

      const res = await addMajorHiddenGovernance(msg);
      this.total = res.data.count;
      this.top = res.data; //卡片数据
      this.tableData = res.data.data;
      // console.log(this.tableData, 147);
    },
    //分页
    handleSizeChange(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize = pagesize;
      this.shuju();
    },
    handleCurrentChange(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.shuju();
    },
    //查询
    query() {
      this.page = 1;

      this.shuju();
    },
    //重置
    reset() {
      this.pro_name = '';
      this.shuju();
    },
    //查看详情
    chakan(row) {
      this.aaa1.name = row.name;
      this.aaa1.corporations = row.corporations;
      this.aaa1.site = row.site;
      this.id = row.id;
      this.digital = 2;
      this.addday2();
    },
    async addday2() {
      let msg = {
        project_id: this.id,
        size: this.pagesize2,
        page: this.page2,
        riskgrade: this.riskgrade,
        audit_type: this.audit_type,
      };
      const res = await addMajorHiddenday2(msg);
      console.log(res, 123789);
      this.total2 = res.data.count;
      this.tableData2 = res.data.data;
    },
    //返回上一级
    superior() {
      this.digital = 1;
      this.shuju();
    },
    //分页day2
    handleSizeChange2(pagesize2) {
      // console.log(`每页 ${pagesize2} 条`);
      this.pagesize2 = pagesize2;
      this.addday2(this.id);
    },
    handleCurrentChange2(page) {
      // console.log(`当前页: ${page}`);
      this.page2 = page;
      this.addday2(this.id);
    },
    // handleSizeChange2(){

    // },
    // queryday2查询
    queryday2() {
      this.addday2();
    },
    //重置
    resetday2() {
      this.riskgrade = '';
      this.audit_type = '';
      this.addday2();
    },
    //day2查看详情
    ck(row) {
      this.aaa2.id = row.id;
      this.aaa2.riskgrade = row.riskgrade;
      this.aaa2.tasktype = row.tasktype;
      this.aaa2.refortime = row.refortime;
      this.aaa2.audit_type = row.audit_type;
      this.aaa2.refortime = row.refortime;
      this.aaa2.reformname = row.reformname;
      this.aaa2.details = row.details
      this.digital = 3;
    },
    //返回上一级
    ReturnThree() {
      this.digital = 2;

    }

  },
  mounted() {
    this.shuju();
  },
  components: {},
  computed: {},
};
</script>
<style lang='less' scoped>
.top {
  width: 100%;
  height: 120px;
}

ol>li {
  list-style: none;
  width: 160px;
  height: 104px;
  background-color: red;
  float: left;
  margin-left: 10px;
  padding: 20px 20px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

ol>li>span {
  margin: 10px;
}

ol>li:nth-child(1) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk1.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(2) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk2.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(3) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk4.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(4) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk4.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(5) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk4.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(6) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk4.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-table {
  margin-top: 10px;
  border-radius: 5px;
}

.con-size {
  width: 100%;
  height: 50px;
}

.con-size p {
  width: 33.3%;
  height: 30px;
  float: left;
  line-height: 30px;
  // border-bottom: 1px solid black;
}

.day3 /deep/ .el-textarea {
  width: 1300px !important;
  height: 200px !important;
}

.day3 /deep/ .el-textarea__inner {
  min-height: 200px !important;
}

.butt {
  margin-bottom: 10px;
}
</style>